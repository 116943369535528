import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";

import Icon1 from "common/src/assets/images/app/features/01.svg";
import Icon2 from "common/src/assets/images/app/features/02.svg";
import Icon3 from "common/src/assets/images/app/features/03.svg";
import Icon4 from "common/src/assets/images/app/features/04.svg";
import Icon5 from "common/src/assets/images/app/features/05.svg";
import Icon6 from "common/src/assets/images/app/features/06.svg";

import ThumbNfcPayments from "common/src/assets/images/app/features/nfc_payments.png";
import ThumbCardPayments from "common/src/assets/images/app/features/card_payments.png";

import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";
import { FaPlay } from "react-icons/fa";
import { Trans } from 'common/src/reusecore/Trans';
import { openLink } from 'common/src/reusecore/Tracking';

import FeturesSectionWrapper from "./features.style";

const Features = () => {
  return (
    <FeturesSectionWrapper id="features">
      <Container fullWidthSM>
        <SectionTitle UniWidth="65%">
          <h4><Trans i18nKey="features.subtitle" /></h4>
          <h2>
            <Trans i18nKey="features.title" />
          </h2>
        </SectionTitle>
        <Row className="feature-1" Vcenter={true}>
          <Col xs={12} sm={6} className="features-col-1">
            <img
              className="feature-thumb"
              src={ThumbCardPayments}
              alt="This is features UNI-Prime"
            />
          </Col>
          <Col xs={12} sm={6} className="features-col-2">
            <div className="features-text">
              <h1><Trans i18nKey="features.card.title" /></h1>

              <ul>
                <li><Trans i18nKey="features.card.features.0" /></li>
                <li><Trans i18nKey="features.card.features.1" /></li>
                <li><Trans i18nKey="features.card.features.2" /></li>
                <li><Trans i18nKey="features.card.features.3" /></li>
              </ul>
              
              <p className="features-text">
                <Trans i18nKey="features.card.text" />
              </p>
            </div>
            <VintageBox>
              <Button onClick={() => openLink('watchCardDemo', 'https://twitter.com/TobiasRuck/status/1199667311516569600')}>
                <FaPlay />
                &nbsp;
                <Trans i18nKey="features.card.watch" />
              </Button>
            </VintageBox>
          </Col>
        </Row>
        <Row className="feature-2" Vcenter={true}>
          <Col xs={12} sm={6} className="features-col-1">
            <img
              className="feature-thumb"
              src={ThumbNfcPayments}
              alt="This is features UNI-Prime"
            />
          </Col>
          <Col xs={12} sm={6} className="features-col-2">
            <div className="features-text">
              <h1><Trans i18nKey="features.nfc.title" /></h1>

              <ul>
                <li><Trans i18nKey="features.nfc.features.0" /></li>
                <li><Trans i18nKey="features.nfc.features.1" /></li>
                <li><Trans i18nKey="features.nfc.features.2" /></li>
                <li><Trans i18nKey="features.nfc.features.3" /></li>
              </ul>

              <p className="features-text">
                <Trans i18nKey="features.nfc.text" />
              </p>

              <VintageBox>
                <Button onClick={() => openLink('watchNfcDemo', 'https://twitter.com/TobiasRuck/status/1261025132971274240')}>
                  <FaPlay />
                  &nbsp;
                  <Trans i18nKey="features.nfc.watch" />
                </Button>
              </VintageBox>
            </div>
          </Col>
        </Row>
        {/*<Row>
          <Col xs={12} sm={6} md={4}>
            <div className="features-block v1">
              <div className="features-icon-block">
                <img src={Icon1} alt="img" />
              </div>
              <h3>Business Opurtunity</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="features-block v2">
              <div className="features-icon-block">
                <img src={Icon2} alt="img" />
              </div>
              <h3>Data Analysis</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="features-block v1">
              <div className="features-icon-block">
                <img src={Icon3} alt="img" />
              </div>
              <h3>Engaging Content</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="features-block v2">
              <div className="features-icon-block">
                <img src={Icon4} alt="img" />
              </div>
              <h3>Web Expertise</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="features-block v1">
              <div className="features-icon-block">
                <img src={Icon5} alt="img" />
              </div>
              <h3>App Development</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="features-block v2">
              <div className="features-icon-block">
                <img src={Icon6} alt="img" />
              </div>
              <h3>Easy Customized</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
          </Col>
        </Row>*/}
      </Container>
    </FeturesSectionWrapper>
  );
};

export default Features;
