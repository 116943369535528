import styled from 'styled-components';

import Bg1 from 'common/src/assets/images/app/features/bg-1.png';
import Bg2 from 'common/src/assets/images/app/features/bg-2.png';

import Vintage1 from 'common/src/assets/images/app/features/vintage-1.png';
import Vintage2 from 'common/src/assets/images/app/features/vintage-2.png';


const FeturesSectionWrapper = styled.section`
    padding: 95px 0 50px 0;
  
    .feature-thumb{
        border-radius: 50%;
    }

    h1 {
        font-size: 30px;
        line-height: 30px;
    }

    @media only screen and (min-width: 569px) {
        .feature-2{
            .features-col-1{
                order: 2;
            }
        }
        .feature-1 .features-col-2{
            padding-left: 5vw;
        }
    }
    
    @media only screen and (min-width: 913px) {
        h1 {
            font-size: 40px;
            line-height: 40px;
        }
        .feature-1 {
            padding-bottom: 25px;
        }
    }
    
    @media only screen and (max-width: 1200px) {
        .feature-1 {
            padding-bottom: 50px;
        }
    }

    .features-icon-block{
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        position: relative;

        img{
            display: block;
            margin: 0 auto;
        }
        
    }
    .features-block{
        
        .features-icon-block{
            &:before{
                position: absolute;
                display: inline-block;
                top: -3px;
                right: 0px;
                height: 70px;
                width: 70px;
                content: '';
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
                transition: all 0.3s linear;
            }
        } 
        &:hover{
            .features-icon-block{
                &:before{
                    opacity: 1;
                    right: -10px;
                }
            }
        }
        &.v1{
            .features-icon-block{
                background: url(${Bg1}) no-repeat;
                background-size: contain;
                &:before{
                    background: url(${Vintage1});
                }
            }
        }
        &.v2{
            .features-icon-block{
                background: url(${Bg2}) no-repeat;
                background-size: contain;
                &:before{
                    background: url(${Vintage2});
                }
            }
        }

        
        h3{
            font-size: 22px; 
            margin-bottom: 15px;
            font-weight: 600;
            line-height: 30px;
        }
        p{
            margin: 0 0 50px 0;
            font-weight: 300;
        }
    }
    @media only screen and (max-width: 912px) {
        padding: 80px 0 0px 0;
    }
    @media only screen and (max-width: 760px) {
        padding: 80px 0 50px 0;
    }
    @media only screen and (max-width: 568px) {
        .features-block{
            text-align: center;
        }
        .features-icon-block{
            margin: 0 auto 25px auto;
        }
        .feature-1, .feature-2{
            padding: 24px;
            
        }
        .feature-1 {
            padding-bottom: 95px;
        }
        h1 {
            padding-top: 25px;
        }
    }
    
`

export default FeturesSectionWrapper;