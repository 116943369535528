import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";

import imgAbout from "common/src/assets/images/app/about.png";
import particle1 from "common/src/assets/images/app/particle/05.png";
import particle2 from "common/src/assets/images/app/particle/06.png";
import { Trans, useTranslation } from 'common/src/reusecore/Trans';
import { openLink } from 'common/src/reusecore/Tracking';

import AboutSectionWrapper from "./about.style";

import { FaPlay } from "react-icons/fa";

const About = () => {
  const {t} = useTranslation();
  return (
    <AboutSectionWrapper id="about">
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            <img
              className="about-thumb"
              src={imgAbout}
            />
          </Col>
          <Col xs={12} sm={6} className="about-content-wrap">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4><Trans i18nKey="app.subtitle" /></h4>
              <h2>
                <Trans i18nKey="app.title" />
              </h2>
            </SectionTitle>
            <p className="about-text text-one">
              <Trans i18nKey="app.text" />
            </p>
            <p className="about-text text-two">
              <Trans i18nKey="app.subtext" />
            </p>
            <VintageBox
              right={true}
              vintageTwo={true}
              position="relative"
            >
              <Button onClick={() => openLink('watchWalletDemo', 'https://twitter.com/TobiasRuck/status/1261025132971274240')}>
                <FaPlay />
                &nbsp;
                <Trans i18nKey="app.watch" />
              </Button>
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  );
};

export default About;
